import { load } from 'recaptcha-v3';
import { recaptchaSiteKey } from '../config';
import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';
import { syncDates } from '../utilities/forms';

export default class {
    constructor({
        id,
        agilysys = false,
        kiosk = false,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const dates = form.querySelectorAll('[type="date"]');
        const submit = form.querySelector('[type="submit"]');
        const formElements = form.elements;

        async function handleSubmit(e) {
            e.preventDefault();

            submit.setAttribute('disabled', true);

            const recaptcha = await load(recaptchaSiteKey);
            const token = await recaptcha.execute('submit');
            const formData = new FormData(form);

            formData.set('token', token);

            // Handle post response
            function cb(res) {
                emitEvent(events.stopLoader);

                const { errors, success, redirect } = JSON.parse(res);

                // Handle errors
                if (errors) {
                    // Reset all field errros to empty
                    Array.from(formData.keys()).map(name => name.replace('[]', '')).forEach(name => {
                        emitEvent(events.showFormErrors, { name, errors: [] });
                    });

                    submit.removeAttribute('disabled');
                    Object.entries(errors).forEach(([name, errs]) => {
                        emitEvent(events.showFormErrors, { name, errors: errs });
                    });

                    el.scrollIntoView({ behavior: 'smooth' });

                    return;
                }

                // Handle success - Disable current form and redirect
                if (success) {
                    form.removeEventListener('submit', handleSubmit);
                    form.remove();

                    if (agilysys) {
                        const arrivalDate = formElements.arrival.value;
                        const departureDate = formElements.departure.value;
                        const packageCode = formElements.packageCode.value;
                        const numAdults = formElements.adults.value;
                        const numChildren = formElements.children.value;

                        window.location = `https://book.rguest.com/onecart/wbe/room/1828/reynoldslakeoconee/${arrivalDate}/${departureDate}/${packageCode}/${numAdults}?children=${numChildren}`;
                    }

                    if (kiosk) {
                        emitEvent(events.formSubmitted);
                    }

                    if (redirect) {
                        window.location = redirect;
                    }
                }
            }

            emitEvent(events.startLoader);

            post({ formData, cb });
        }

        // Initialize with dynamic CSRF input
        fetch('/actions/blitz/csrf/input')
            .then(result => result.text())
            .then(result => {
                form.insertAdjacentHTML('afterbegin', result);
                submit.removeAttribute('disabled');
                form.addEventListener('submit', handleSubmit);
            });

        if (dates.length === 2) {
            syncDates(...dates);
        }
    }
}
